<template>
    <div class="d-flex align-items-center justify-content-center" style="height: 100vh;">
        <div class="d-flex justify-content-center align-items-center flex-column mb-5">
        <img height="200" width="200" :src="require('../assets/images/blocked.webp')" />
        <div class="mb-5">
        <h3>Your Account Was Blocked</h3>
        <center><p>Kindly Contact The Administrator</p></center>
        </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>